/* features */
 .feature-box {
     padding: 20px;
     background-color: rgb(255, 255, 255);
     box-shadow: 0px 8px 16px 0px rgba(205, 190, 255, 0.2);
}
 .feature-small-content h3 {
     font-size: 22px;
     margin-bottom: 15px;
}
