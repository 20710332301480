* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

table {
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  font-size: .9em;
  border-spacing: 0;
  border-collapse: collapse;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table td,
table th {
  padding: 15px;
  line-height: 1.5;
  vertical-align: top;
  border: 1px solid hsla(0, 0%, 50.2%, .5019607843137255);
}

.accordion-title {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.accordion-icon {
  margin-inline: 10px;
}

.accordion {
  cursor: pointer;
  box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
  border-bottom: 1px solid #eaedff
}

.custom-image-container {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 10px;
}

.custom-image-container img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.custom-image-container-main {
  width: 600px;
  height: 460px;
  overflow: hidden;
}

.custom-image-container-main img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.sub-custom-header {
  color: #e12454;
}

.footer-p {
  color: #647589;
  font-size: 18px;
}

.custom-slider-div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  border: 1px solid green;
  border-radius: 10px;
  margin: 10px
}

.custom-slider-div p {
  font-weight: bold;
  height: 300px;
  padding: 15px;
  overflow: hidden
}

.custom-slider-img {
  height: 60px;
  width: 60px;
  margin: 10px;
}

.custom-main-header {
  font-size: 48px;
}

.custom-main-title {
  font-size: 68px !important;
}

.whatsapp_float {
  position: fixed;
  bottom: 60px;
  right: 60px;
  border-radius: 50px;
  text-align: center;
  font-size: 35px;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 12px;
}

.hidden-header {
  height: 200px;
  display: none;
}

@media screen and (max-width: 991px) {
  .hidden-header {
    height: 200px;
    display: block;
  }
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    bottom: 20px;
    right: 20px;
  }

  .mobile-margin {
    margin-top: 30px !important;
  }

  .mobile-hours-btn {
    padding: 18px !important;
  }
}

@media only screen and (max-width: 768px) {
  .custom-main-header {
    font-size: 30px;
  }

  .custom-main-title {
    font-size: 40px !important;
  }

  .side-menu-icon {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 535px) {
  .sticky-hamburger-menu {
    margin-top: 40px;
  }
}